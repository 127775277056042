<template lang="pug">
  div
    .sidebar-layout__toolbar
      button.btn.btn--brand(
        v-permission-hide="permissions.create_user"
        @click="modals.showCreateNew = true")
        font-awesome-icon.mr-1(
          size="sm"
          icon="plus")
        span {{ 'actions.add_user' | translate }}
      v-dialog(
        max-width="348"
        v-model="modals.showCreateNew")
        create-new-modal(
          @close="modals.showCreateNew = false")

    navigation
    //- FOR DESKTOP AND TABLETS
    template(v-if="!isMobile")
      employees-table

    //- FOR MOBILE ONLY
    template(v-if="isMobile")
      employees-table-mobile

</template>

<script>
import { mapGetters } from 'vuex'

import adaptationMixin from '@/mixins/adaptation.mixin'

import Navigation from '../common/Navigation'

import CreateNewModal from './modals/CreateNewModal'
import PermissionModal from './modals/PermissionModal'
import permissions from '@/util/permissions'
import roles from '@/util/roles'

const EmployeesTable = () => import('./EmployeesTable')
const EmployeesTableMobile = () => import('./EmployeesTableMobile')

export default {
  name: 'SettingsEmployees',

  mixins: [adaptationMixin],

  components: {
    EmployeesTable,
    EmployeesTableMobile,
    Navigation,
    CreateNewModal,
    PermissionModal
  },

  data: () => ({
    modals: {
      showPermission: false,
      showCreateNew: false
    },
    isVisiblePermission: false,
    permissions: permissions
  }),

  computed: {
    isHasRole () {
      return this.user.user.role === roles.director
    },

    ...mapGetters([
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
